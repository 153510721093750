function slingshot() {
  const hostname = window.location.hostname
  const path = window.location.pathname

  fetch('__INTEGRATION_ADAPTER_URL__/slingshot_forms?hostname=' + hostname + '&path=' + path)
    .then(response => response.json())
    .then(data => createFormListener(data))
    .catch((error) => {
      console.error('Error:', error);
    });
}

function createFormListener(forms) {
  if (forms === undefined || forms.length === 0) {
    return
  }

  var intervalId = setInterval(function() {
    var element = document.querySelector(forms[0].query_selector);
    if (!element) {
      return;
    }

    addListener(element, forms[0]);
    clearInterval(intervalId);
  }, 500);
}

function addListener(element, form) {
  element.addEventListener(form.event, function(e){
    try {
      if (typeof grecaptcha !== 'undefined' && grecaptcha.getResponse() === '' ) {
        return;
      }
    } catch (e) {
     // Do nothing if grecaptcha is defined but has no clients, this happens with GoDaddy's invisible recaptcha 
    }

    fetch('__INTEGRATION_ADAPTER_URL__/slingshots', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: jsonBody(form)
    })
  });
}

function jsonBody(form) {
  let data = {}

  // Remove after slingshot team group routing goes live. This lets us have a graceful transition between routing mechanisms
  team_id = form.team_id
  if (team_id !== undefined) {
    data.team_id = team_id
  }

  team_group_id = form.team_group_id
  if (team_group_id !== undefined) {
    data.team_group_id = team_group_id
  }

  let inputs = [
    {
      name: 'integration',
      value: 'leadsigma-js'
    }
  ];
  form.fields.forEach(function(field) {
    if (field.slingshot_field.name === 'name') {
      let names = document.querySelector(field.query_selector).value.split(' ')
      inputs.push({
        name: 'first_name',
        value: names[0]
      })
      inputs.push({
        name: 'last_name',
        value: names[1] || ''
      })
    }
    if (field.slingshot_field.name === 'phone') {
      inputs.push({
        name: field.slingshot_field.name,
        value: document.querySelector(field.query_selector)?.value?.replace(/[^0-9]/g, '')
      })
      return;
    }
    inputs.push({
      name: field.slingshot_field.name,
      value: document.querySelector(field.query_selector).value
    })
  });
  data['inputs'] = inputs;
  return JSON.stringify(data);
}

window.addEventListener("load", function() {
  slingshot();
}, false);
